import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-scroll";

import { Title, Button, Section, Box, Text } from "../../components/Core";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Box>
              <Text
                variant="tag"
                mb={4}
                className="text-uppercase"
                color="heading"
              >
                The 'Uber' of genuine, verified, certifications!
              </Text>
              <div>
                <Title variant="hero">
                  Human Coaches, Certifications, Markets and Learners.
                </Title>
              </div>

              <Box mt="52px">
                <Link
                  to="works"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>How this works</Button>
                </Link>
              </Box>
            </Box>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
